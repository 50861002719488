import { useRouter } from 'next-translate-routes/router';
import React, { type FC, type ReactNode, useEffect, useState } from 'react';

import { Footer, Header } from '@/components';
import { MobileBottomBar } from '@/components/MobileBottomBar/MobileBottomBar';
import { ApiProvider } from '@/contexts/ApiContext';
import { cn } from '@/utils';
import { geologica, inter } from '@/utils/fonts';
import { isMobileApp } from '@/utils/isMobileApp';

import { addPropsToChildren } from './main.utils';

type IMainProps = {
  meta: ReactNode;
  children: ReactNode;
  isHeaderMinified?: boolean;
  shouldHeaderBeBlue?: boolean;
  isHeaderSticky?: boolean;
  isHeaderVisible?: boolean;
  isFooterVisible?: boolean;
  mobileTitle?: string;
  className?: string;
};

const Main: FC<IMainProps> = ({
  meta,
  children,
  isHeaderMinified = false,
  shouldHeaderBeBlue,
  isHeaderSticky = true,
  isHeaderVisible = true,
  isFooterVisible = true,
  mobileTitle,
  className,
}) => {
  const [scrolled, setScrolled] = useState(false);
  const [scrollPosition, setScrollPosition] = useState<'up' | 'down'>();

  const isScrolledDown = scrollPosition === 'down' && scrolled;
  const isScrolledUp = scrollPosition === 'up' && scrolled;
  const router = useRouter();

  let lastScrollTopPosition =
    typeof document !== 'undefined' &&
    (window.scrollY || document.documentElement.scrollTop);

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }

    const scrollTopPosition =
      window.scrollY || document.documentElement.scrollTop;

    if (lastScrollTopPosition && scrollTopPosition > lastScrollTopPosition) {
      setScrollPosition('down');
    }
    if (lastScrollTopPosition && scrollTopPosition < lastScrollTopPosition) {
      setScrollPosition('up');
    }

    lastScrollTopPosition = scrollTopPosition <= 0 ? 0 : scrollTopPosition;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, false);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <>
      {meta}

      <div
        className={cn(
          'pt-[60px] md:mx-0 lg:pt-[80px]',
          inter.variable,
          geologica.variable,
          !isHeaderVisible && 'pt-0 lg:pt-0',
        )}
      >
        <ApiProvider>
          {isHeaderVisible && (
            <Header
              isScrolledDown={isScrolledDown}
              isScrolled={scrolled}
              isMinified={isHeaderMinified}
              shouldHeaderBeBlue={shouldHeaderBeBlue}
              isHeaderSticky={isHeaderSticky}
              mobileTitle={mobileTitle}
            />
          )}

          {/* TODO: add min height without header and footer */}
          <main
            data-testid="mainSection"
            className={cn('safe-area min-h-[200px] flex-1', className)}
          >
            {/* <ReCAPTCHA */}
            {/*  ref={captchaRef} */}
            {/*  className={cn(!isOpenModal && 'hidden')} */}
            {/*  size="invisible" */}
            {/*  sitekey={process.env.NEXT_PUBLIC_RECAPTHA_SITE_KEY || ''} */}
            {/*  onChange={handleChangeCaptcha} */}
            {/* /> */}
            {addPropsToChildren(children, {
              isScrolledDown,
              isScrolledUp,
            } as React.Attributes)}
          </main>
          {isFooterVisible && !isMobileApp() && (
            <Footer isFooterMinified={isHeaderMinified} />
          )}
        </ApiProvider>
      </div>
      {isMobileApp() && !router.pathname.includes('/mobile-app') && (
        <MobileBottomBar />
      )}
    </>
  );
};

export { Main };
